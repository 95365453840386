@charset "utf-8";

@import "bulma/sass/utilities/_all";
@import "bulma/sass/base/_all";

//@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
//$family-sans-serif: "Nunito", sans-serif;

@font-face {
    font-family: 'BloggerSans';
    src: url('./fonts/blogger_sans-webfont.woff2') format('woff2'),
         url('./fonts/blogger_sans-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'BloggerSans';
    src: url('./fonts/blogger_sans-bold-webfont.woff2') format('woff2'),
         url('./fonts/blogger_sans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'LeagueSpartan';
    src: url('./fonts/LeagueSpartan-Bold.woff2') format('woff2'),
         url('./fonts/LeagueSpartan-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

.container {
    background-color: $white;
}

.main-content {
    background-color: transparent;
}

@media screen and (max-width: 1024px) {
  .main-content {
    background: $white;
  }
}

$family-sans-serif: "BloggerSans", sans-serif;
$table-background-color: transparent;

$dimensions: 128 512;

$navbar-background-color: transparent;
$navbar-item-hover-background-color: transparent;

$box-shadow: none;
$pagination-border-color: none;

//@import "bulma/sass/elements/_all";
    @import "bulma/sass/elements/box.sass";
    @import "bulma/sass/elements/button.sass";
    @import "bulma/sass/elements/container.sass";
    @import "bulma/sass/elements/content.sass";
    @import "bulma/sass/elements/icon.sass";
    @import "bulma/sass/elements/image.sass";
    @import "bulma/sass/elements/notification.sass";
    //@import "bulma/sass/elements/progress.sass";
    @import "bulma/sass/elements/table.sass";
    //@import "bulma/sass/elements/tag.sass";
    @import "bulma/sass/elements/title.sass";
    @import "bulma/sass/elements/other.sass";

//@import "bulma/sass/form/_all";
    @import "bulma/sass/form/shared.sass";
    @import "bulma/sass/form/input-textarea.sass";
    @import "bulma/sass/form/checkbox-radio.sass";
    @import "bulma/sass/form/select.sass";
    @import "bulma/sass/form/file.sass";
    @import "bulma/sass/form/tools.sass";

//@import "bulma/sass/components/_all";
    //@import "bulma/sass/components/breadcrumb.sass";
    //@import "bulma/sass/components/card.sass";
    //@import "bulma/sass/components/dropdown.sass";
    @import "bulma/sass/components/level.sass";
    @import "bulma/sass/components/media.sass";
    @import "bulma/sass/components/menu.sass";
    //@import "bulma/sass/components/message.sass";
    //@import "bulma/sass/components/modal.sass";
    @import "bulma/sass/components/navbar.sass";
    @import "bulma/sass/components/pagination.sass";
    //@import "bulma/sass/components/panel.sass";
    //@import "bulma/sass/components/tabs.sass";

//@import "bulma/sass/grid/_all";
    @import "bulma/sass/grid/columns.sass";
    //@import "bulma/sass/grid/tiles.sass";

@import "bulma/sass/helpers/_all";

//@import "bulma/sass/layout/_all";
    @import "bulma/sass/layout/hero.sass";
    @import "bulma/sass/layout/section.sass";
    //@import "bulma/sass/layout/footer.sass";

// background image
#root { 
        background-image: url("./graphix/background.png");
        background-position: center center;
        background-repeat:  no-repeat;
        background-attachment: fixed;
        background-size: 1920px 1080px;
        background-color: #999;

 }
// Player css

@import "bulma-slider/index.sass";

.main-content {
    min-height: 100vh;
}

.player {
    border-top: 1px solid $border;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $body-background-color;
}

.entry-list {
    text-align: center;
}

.entry-list-item {
    text-align: left;
    display: inline-flex;
    min-width: 296px;
    margin: 0 2.5em 1.5em 0;
    figure {
        margin-right: 0.5em;
    }
}

// navbar burger

//#nav-toggle-state {
//    display: none;
//}
//#nav-toggle-state:checked ~ .navbar-menu {
//    display: block;
//}

.navbar-item {
    font-family: LeagueSpartan, sans-serif;
}